
.__points {
  padding: 0 1rem 1rem;
}
.__points h4 {
  margin: 0;
  margin-bottom: 1rem;
  /* color: grey; */
  font-weight: 600;
}
.__points h4 span {
  color: grey;
  /* color: var(--promo-color);
  font-weight: 600; */
}
.__points .__points-banner {
  /* background-color: var(--ion-color-primary-soft); */
  background-color: #fff;
  color: #fff;
  padding: 1rem;
  /* border: 1px solid var(--ion-color-primary-soft); */
}

.__points .__points-banner p {
  margin: 0;
  /* color: var(--ion-color-primary); */
}
.__points .__points-banner p strong {
  color: var(--ion-color-danger);
}
.__points .__points-banner .__points-bottom {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}
.__points .__points-banner .__points-bottom .__actual-points {
  margin-right: .5rem;
  font-weight: bold;
  /* color: var(--ion-color-danger); */
}
.__points .__points-banner .__points-bottom .__next-points {
  margin-left: .5rem;
  font-weight: bold;
  /* color: var(--ion-color-danger); */
}
.__points .__points-banner .__points-bar {
  width: 100%;
  height: 6px;
  background: var(--ion-color-primary-soft);
  border-radius: 3px;
  /* border: .55px inset var(--ion-color-primary); */
}
.__points .__points-banner .__points-bar .__bar-progression {
  height: 100%;
  background-color: var(--ion-color-primary);
  border: 1px solid var(--ion-color-primary-soft);
  border-radius: 3px;
}