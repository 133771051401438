.__shops-page .__shops-page-favourites h4 {
  padding: 0 1rem .5rem;
  margin: 0;
}

.__shops-page .__shops-page-brands h4 {
  padding: .5rem 1rem;
  margin: 0;
}

.__shops-page .__gradient {
  display: none;
}

.__shops-page .__shops-carousel {
  padding-bottom: 0;
}

.__shops-section {
  display: flex;
  flex-wrap: wrap;
}

.__shop-card {
  min-height: 100%;
  /* width: calc(50% - 1rem); */
  /* margin: 0.5rem; */
  width: 50%;
  text-decoration: none;
  border: .55px solid lightgray;
}

.__shop-card .__shop-card-img {
  height: 150px;
}

.__shop-card .__shop-card-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.__shop-card .__card-content {
  /* padding: 0 1rem 1rem; */
}

.__shop-card .__card-content .__card-title {
  padding: 0 1rem;
  color: var(--ion-text-color, #000);
  -webkit-box-orient: vertical;
  /* display: block; */
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  font-size: 1rem;
  /* border: 0.55px solid var(--ion-border-color); */
  /* border: 2px solid black; */
}

.__shop-card .__card-content .__distance {
  padding: .5rem 1rem .5rem;
  background-color: var(--ion-color-light-tint);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: .8rem;
  color: grey;
}