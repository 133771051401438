.__prodotti-page .__category-img {
  height: 230px;
  width: 100%;
}
.__prodotti-page .__category-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  display: block;
}