.__login-page ion-content > div {
  background-color: var(--ion-color-primary);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.__login-page img {
  width: 150px;
}
.__login-page h1 {
  margin: 0;
  margin-bottom: 2rem;
  font-size: 2rem;
  max-width: max-content;
  color: #fff;
  font-family: 'Lobster', cursive;
  letter-spacing: 1px;
}
.__login-page .__login-module .__input {
  margin-right: 0 !important;
  margin-bottom: 1.5rem;
  border-radius: 1rem;
  padding: .3rem 1rem;
  overflow: hidden !important;
  width: 300px;
  background-color: white;
}
.__login-page .__login-module .__error {
  margin-bottom: 1.5rem;
  color: #eb445a;
}
.__login-page .__login-module ion-button {
  margin: 0 auto;
  display: block;
  width: 150px;
  /* width: 300px; */
}