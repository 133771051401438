.__favorite-shops-carousel {
  display: flex;
  overflow-x: scroll;
  padding: .5rem 1rem;
  padding-right: 60px !important;
}

.__favorite-shops-carousel .__favorite-shop-carousel-card {
  border-radius: 6px;
  /* height: 150px; */
  height: 100%;
  width: 150px;
  background-color: #fff;
  overflow: hidden;
  text-align: left;
  border: .55px solid lightgray;
}
.__favorite-shops-carousel .__favorite-shop-carousel-card .__shop-img {
  height: 80px;
}
.__favorite-shops-carousel .__favorite-shop-carousel-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  display: block;
}
.__favorite-shops-carousel .__favorite-shop-carousel-card .__favorite-shop-text {
  padding: .5rem;
}
.__favorite-shops-carousel .__favorite-shop-carousel-card .__favorite-shop-text p {
  padding: 0;
  margin: 0;
}
.__favorite-shops-carousel .__favorite-shop-carousel-card .__favorite-shop-text .__favorite-shop-brand {
  font-size: .8rem;
  margin-top: .5rem;
  color: var(--ion-color-danger);
  font-weight: 500;
}
.__favorite-shops-carousel .__favorite-shop-carousel-card .__favorite-shop-text .__favorite-shop-address {
  font-size: .8rem;
  margin-top: .5rem;
  color: grey;
}