@import url("./font.css");
@import url("./colors.css");
@import url("./_common.css");
@import url("./_ProductCard.css");
.__prova {
  position: absolute;
  z-index: 100000;
  width: 100%;
  height: 502px;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

html.plt-ios.plt-pwa {
  height: 99.9vh !important;
}
body {
  background-color: var(--ion-color-primary);
}