.__shop-page .__shop-page-content {
  /* position: relative; */
}

.__shop-page .__shop-heading {
  background-color: #fff;
  position: relative;
  z-index: 2;
}

.__shop-page .__shop-heading-top {
  display: flex;
  align-items: center;
  padding: 1rem;
  border-bottom: .55px solid lightgray
}

.__shop-page .__avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  padding: 3px;
  border-width: 2px;
  border-color: var(--ion-color-primary);
  margin-right: 1rem;
}

.__shop-page .__avatar img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.__shop-page h1 {
  font-size: 1.2rem;
  font-weight: 500;
}

.__shop-page .__action-buttons {
  margin-left: auto;
  margin-right: 0;
  text-align: right;
}

.__shop-page .__follow-btn.__follow,
.__shop-page .__chat-btn {
  --background: var(--ion-color-danger);
}

.__shop-page .__follow-btn.__unfollow {
  --background: var(--ion-color-danger-soft);
  /* --color: var(--ion-color-danger); */
}

.__shop-page .__shop-page-content ion-item-divider {
  --inner-padding-end: 0;
  --background: white;
  margin: 0;
  padding: 0;
  z-index: 1;
  border-bottom: .55px solid lightgray;
  top: 0;
  left: 0;
  width: 100%;
}

.__shop-page .__segments {
  /* position: sticky !important; */
  /* position: -webkit-sticky; */
  /* position: sticky;
  top: 200px !important;
  left: 0px; */
  width: 100%;
}

.__shop-info {
  padding: 0 1rem;
}

.__shop-info-heading h4 {
  margin: 0;
}

.__shop-info-element {
  border-bottom: .55px solid lightgray;
  padding: .5rem;
  display: flex;
  align-items: center;
}

.__shop-info-element ion-router-link:not(.__maps-link),
.__shop-info-element ion-icon:not(.__maps-link),
.__shop-info-element a {
  text-decoration: none;
}

.__shop-info-element ion-router-link:not(.__maps-link),
.__shop-info-element ion-icon:not(.__maps-link),
.__shop-info-element a:not(.__maps-link) {
  color: #555;
}

.__shop-info-element.__hours {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.__shop-info-element span {
  color: #555;
  display: inline-block;
}

.__shop-info-element ion-icon {
  margin-right: .5rem;
  flex-shrink: 0;
}

.__shop-info-element:last-of-type {
  margin-bottom: 1rem;
}

.__shop-info-map {
  border-radius: 8px;
  overflow: hidden;
  height: 200px;
  margin-bottom: 1rem;
}

.__shop-info-map img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.__shop-info-header {
  /* display: flex; */
  /* align-items: center; */
  font-weight: 600;
  /* font-size: 1.3rem; */
}

.__shop-page .__products .__products-title {
  padding-top: 0;
}