
.__post-social ion-button {
  color: #a8a8a8;
}
.__post-settings {
  position: absolute;
  top: .5rem;
  right: .5rem;
}
.__post-settings ion-button {
  --padding-start: 1rem;
  --padding-end: 0;
  margin: 0;
}
.__post-user {
  display: flex;
}
.__post-user-avatar {
  width: 50px;
  height: 50px;
  min-width: 50px;
  min-height: 50px;
  border-radius: 50%;
  margin-right: 1rem;
  overflow: hidden;
  border: .55px solid lightgray;
}
.__post-user-avatar img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
.__post-text {
  padding: 0 1rem 1rem 1rem;
}
.__post-text a {
  text-decoration: none;
}
.__promozione-media {
  display: block;
  width: 100%;
  height: 200px;
  overflow: hidden;
  padding: 0;
  position: relative;
  /* margin-bottom: 1rem; */
}
.__post-media-geometry {
  margin: 1rem 1rem;
  width: calc(100% - 2rem);
  height: calc(100% - 2rem);
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid lightgray;
}
.__promozione-media img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: relative;
  /* border-radius: 4px; */
}
.__post-data {
  padding-left: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgba(0, 0, 0, 0.7);
}
.__post-data .__icon {
  margin-right: .5rem;
}
.__post-likes, .__post-comments {
  display: flex;
  align-items: center;
}
.__post-likes .__likes-avatars {
  display: flex;
  align-items: center;
  margin-right: .5rem;
}
.__post-likes .__likes-avatars .__likes-avatar {
  overflow: hidden;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 1px solid var(--bg-color);
  position: relative;
  /* box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3); */
}
.__post-likes .__likes-avatars .__likes-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.__post-likes .__likes-avatars .__likes-avatar:nth-child(1) {
  z-index: 3;
}
.__post-likes .__likes-avatars .__likes-avatar:nth-child(2) {
  z-index: 2;
  margin-left: -5px;
}
.__post-likes .__likes-avatars .__likes-avatar:nth-child(3) {
  z-index: 1;
  margin-left: -5px;
}
.__post-actions {
  /* padding: .5rem; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-top: .55px solid lightgray; */
}
.__post-actions .__icon {
  /* margin-right: .5rem; */
}
.__post-actions ion-button, .__post-actions .button-native {
  margin: 0;
  padding: 0;
  --padding-start: 0;
  --padding-end: 1rem;
}
.__post-products ion-accordion {
  /* --border-width: 0 */
  /* border-bottom: .55px solid lightgray; */
}
.__post-products .accordion-collapsed ion-item,
.__post-products .accordion-collapsing ion-item,
.__post-products .accordion-expanded ion-item,
.__post-products .accordion-expanding ion-item {
  --background: var(--bg-color);
  /* --color: var(--ion-color-primary); */
  --color: #a8a8a8;
}
.__post-products ion-icon {
  /* --color: var(--ion-color-primary); */
}
.__post-products .__post-products-list {
  background-color: var(--bg-color);
  /* border-top: .55px solid lightgray; */
  padding: 0;
  /* padding-right: .5rem; */
}

.__post-products-list .__card-carousel.__card {
  width: calc(100% / 2);
  min-width: calc(100% / 2);
}

@media screen and (max-width: 400px) {
  .__post-products-list .__card-carousel.__card {
    width: calc(100% / 1.4);
    min-width: calc(100% / 1.4);
  }
}
.__post-products .__post-products-list .__carousel-container {
  padding: 1rem;
  padding-right: 60px ;
}
.__promo-text {
  padding: .3rem 1rem 0;
  font-size: .9rem;
}
.__promo-header {
  display: flex;
  padding: 1rem;
}
.__promo-header .__promo-avatar {
  width: 50px;
  height: 50px;
  border-radius: 8px;
  /* margin: 1rem 0 0 1rem; */
  overflow: hidden;
  box-shadow: 0 3px 16px rgba(0, 0, 0, 0.08);

}
.__promo-header h3 {
  padding-bottom: 0;
  /* font-size: 1.2rem; */
}
.__promo-top {
  text-align: center;
  padding: .5rem;
  color: #fff;
  background: var(--promo-color);
  font-weight: 600;
}