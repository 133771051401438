

.__cart-card {
  display: flex;
  margin-bottom: 1rem;
}
.__cart-card .__preview-space {
  /* background: red; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.__cart-card .__preview {
  width: 120px;
  height: 120px;
  padding: 8px;
}

.__cart-card .__preview img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.__cart-card .__content {
  height: 100%;
  width: 100%;
  /* min-width: 60%; */
  /* max-width: 60%; */
}

.__cart-card .__content a {
  color: #000;
  text-decoration: none !important;
}

.__cart-card .__content h3 {
  font-size: .9rem;
  font-weight: 400;
}

.__cart-card .__content .__price {
  /* width: 50%; */
  /* margin-left: auto;
  margin-right: 0; */
  display: flex;
  align-items: center;
}

.__cart-card .__content .__price .__new-price {
  display: block;
  /* height: 24px; */
  /* padding: 0 8px; */
  /* border-radius: 12px; */
  /* background-color: var(--ion-color-primary); */
  color: #000;
  font-weight: 600;
  /* line-height: 24px; */
  font-size: 1.2rem;
}

.__cart-card .__content .__price .__discount {
  font-weight: 600;
  color: var(--ion-text-color, #000);
}

.__cart-card .__content .__price .__old-price {
  text-decoration: line-through;
  display: block;
  font-weight: 400;
  color: grey;
}

.__cart-card .__content .__quantity {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.__cart-card .__content .__remove {
  text-align: end;
}