.__hero .__carousel-container {
  padding: 1rem;
}
.__hero .__hero-card {
  width: calc(100% / 3);
  height: 160px;
  border-radius: 16px;
  background: #fff;
  position: relative;
  overflow: hidden;
  padding: 1rem;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* color: #fff; */
  font-weight: 400;
  cursor: pointer;
}
@media screen and (max-width: 400px) {
  .__hero .__hero-card {
    height: 110px;
  }
}
.__hero .__hero-card:nth-child(1) {
  /* background: var(--ion-color-primary-soft); */
}
.__hero .__hero-card:nth-child(1) p {
  /* color: var(--ion-color-primary); */
}
.__hero .__hero-card:nth-child(2) {
  /* background: var(--ion-color-primary-soft); */
}
.__hero .__hero-card:nth-child(2) p {
  /* color: var(--ion-color-danger); */
}
.__hero .__hero-card:nth-child(3) {
  /* background: var(--ion-color-primary-soft); */
}
.__hero .__hero-card:nth-child(3) p {
  /* color: var(--ion-color-blue); */
}
.__hero .__hero-card:not(:last-of-type) {
  margin-right: 1rem;
}
.__hero .__hero-card img {
  height: auto;
  max-height: 70px;
  width: auto;
}
.__hero .__hero-card p {
  margin: 0;
  /* color: #fff; */
  font-weight: 600;
  text-transform: capitalize;
  /* color: var(--ion-color-primary); */
}