.__section-title {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.__section h2 {
  font-size: 1.3rem;
  font-weight: 600;
  margin: 0;
}
.__section ion-item {
  /* --background: green */
}