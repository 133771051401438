.__single-product-offer-card .__single-product-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1rem;
  border-bottom: .55px solid lightgray;
}

.__product-offer-img {
  height: 170px;
  display: block;
}

.__product-offer-img img {
  display: block;
  margin: 0 auto;
  /* width: 100%; */
  height: 100%;
  /* object-fit: cover; */
}

.__single-product-top {
  text-decoration: none;
  color: unset;
}

.__single-product-top h3 {
  padding: 0 1rem 1rem;
  margin: 0;
  font-size: 1.2rem;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
}

.__single-product-offer-card .__single-product-shops {
  border-radius: 0 0 16px 16px;
  box-shadow: none;
  padding-bottom: .5rem;
}

.__single-product-offer-card .__single-product-shops .__shops-list-title {
  /* background: var(--ion-background-color); */
  background: var(--bg-color);
  /* background: #ffa6a6; */
  /* color: var(--ion-color-danger); */
  color: grey;
  font-weight: 400;
  /* border-bottom: .55px solid lightgray; */
}

.__single-product-offer-card .__single-product-shops .__shops-list-title h3 {
  font-weight: 400;
  padding-bottom: 0;
}

.__single-product-offer-card ion-item  {
  --padding-start: 1rem;
  --padding-end: 1rem;
  --inner-padding-end: 0;
}
.__single-product-offer-card ion-item ion-avatar {
  margin-right: 1rem;
}

/* .__single-product-offer .__single-product-shops {
  padding-right: .5rem;
} */