
.__single-product-shops {
  background: var(--bg-color);
  /* border: 1px solid black; */
  border-radius: 4px;
  /* box-shadow: 0 3px 16px rgba(0, 0, 0, 0.08); */
}
.__single-product-shops ion-item {
  --background: var(--bg-color) !important;
}
.__shops-list-title {

  /* background: var(--ion-color-primary); */
  /* color: white; */
}
.__shops-list-title h3 {
  font-size: 1.2rem;
  margin: 0;
  padding: 1rem;
  /* border-top: 0.55px solid lightgray; */
  /* color: var(--ion-color-primary); */
}
/* .__single-product-shop img {
  object-fit: cover;
} */
.__single-product-shop h3 {
  font-size: 1rem;
}
.__best-offer {
  /* --background: var(--ion-color-primary) !important; */
  /* color: var(--ion-color-primary); */
  /* border-radius: 4px; */
}
.__best-offer h3,
.__best-offer ion-text {
  font-weight: 600;
}
.__best-offer p {
  color: black;
  /* font-weight: 500; */
}
.__normal-offer {
  color: rgba(0, 0, 0, 0.7) !important;
}