
.__post-social ion-button {
  color: #a8a8a8;
}
.__post-settings {
  position: absolute;
  top: .5rem;
  right: .5rem;
}
.__post-settings ion-button {
  --padding-start: 1rem;
  --padding-end: 0;
  margin: 0;
}
.__post-user {
  display: flex;
}
.__post-user-avatar {
  width: 50px;
  height: 50px;
  min-width: 50px;
  min-height: 50px;
  border-radius: 50%;
  margin-right: 1rem;
  overflow: hidden;
  border: .55px solid lightgray;
}
.__post-user-avatar img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
.__post-user-info {
  
}
.__post-user-info .__post-user-name {
  font-weight: 500;
  font-size: 1.2rem;
  margin-bottom: .1rem;
  display: flex;
  align-items: center;
}
.__post-user-info .__post-user-name ion-icon {
  margin-top: .1rem;
  margin-left: .3rem;
  color: var(--ion-color-primary);
  --ionicon-stroke-width: 3rem;
}
.__post-user-info .__post-user-date {
  font-size: .8rem;
}
.__post-text {
  padding: 0 1rem 1rem 1rem;
  font-weight: 400;
}
.__post-text a {
  text-decoration: none;
}
.__post-media {
  width: 100%;
  max-height: 500px;
  overflow: hidden;
  padding: 0;
  position: relative;
  /* margin-bottom: 1rem; */
}
.__post-media-geometry {
  margin: 1rem 1rem;
  width: calc(100% - 2rem);
  height: calc(100% - 2rem);
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid lightgray;
}
.__post-media img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  position: relative;
  /* border-radius: 4px; */
}
.__post-data {
  padding-left: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgba(0, 0, 0, 0.7);
}
.__post-data .__icon {
  margin-right: .5rem;
}
.__post-likes, .__post-comments {
  display: flex;
  align-items: center;
}
.__post-likes .__likes-avatars {
  display: flex;
  align-items: center;
  margin-right: .5rem;
}
.__post-likes .__likes-avatars .__likes-avatar {
  overflow: hidden;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 1px solid var(--bg-color);
  position: relative;
}
.__post-likes .__likes-avatars .__likes-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.__post-likes .__likes-avatars .__likes-avatar:nth-child(1) {
  z-index: 3;
}
.__post-likes .__likes-avatars .__likes-avatar:nth-child(2) {
  z-index: 2;
  margin-left: -5px;
}
.__post-likes .__likes-avatars .__likes-avatar:nth-child(3) {
  z-index: 1;
  margin-left: -5px;
}
.__post-likes .__text {
  /* font-weight: 400; */
  font-size: .9rem;
}
.__post-actions {
  /* padding: .5rem; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-top: .55px solid lightgray; */
}
.__post-actions .__icon {
  /* margin-right: .5rem; */
}
.__post-actions ion-button, .__post-actions .button-native {
  margin: 0;
  padding: 0;
  --padding-start: 0;
  --padding-end: 1rem;
}
.__post-products ion-accordion {
  /* border-bottom: .55px solid lightgray; */
}
.__post-products .accordion-collapsed ion-item,
.__post-products .accordion-collapsing ion-item,
.__post-products .accordion-expanded ion-item,
.__post-products .accordion-expanding ion-item {
  --background: var(--bg-color);
  /* --color: var(--ion-color-primary); */
  --color: #a8a8a8;
}
.__post-products ion-icon {
  /* --color: var(--ion-color-primary); */
}
