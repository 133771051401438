.__carrello ion-list-header {
  margin: 0;
}
.__cart-list-title {
  margin: 0;
  margin-bottom: 1rem;
  padding: 0 1rem;
  font-size: 1.2rem;
  font-weight: 500;
}
.__costo-carrello {
  text-align: end;
  padding: 0 1rem;
}
.__carrello .__costo-carrello {
  margin-bottom: 5rem;
}
.__costo-carrello h5 strong {
  font-size: 1.3rem;
}
/* .__costo-carrello .__extotal {
  color: #999;
  font-size: 1rem;
}
.__costo-carrello .__extotal strong {
  font-size: 1.1rem;
} */
.__costo-carrello .__total-points strong {
  color: var(--ion-color-danger);
}