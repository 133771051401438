::-webkit-scrollbar {
  display: none;
}

ion-toolbar {
  --background: var(--ion-color-primary);
  /* --background: var(--bg-color); */
  --color: #fff;
}

ion-toolbar ion-button,
ion-toolbar ion-back-button {
  --color: #fff;
  /* --color: #000; */
}

ion-toolbar ion-button {
  /* background: rgba(255,255,255,0.3); */
  /* border-radius: 50%; */
  /* width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  max-width: 30px;
  max-height: 30px; */

}

ion-content ion-toolbar {
  --color: #000;
  --background: unset;
}


.__uppercase {
  text-transform: uppercase;
}

ion-tab-bar {
  /* --background: var(--ion-background-color); */
  --background: var(--blue-color);
  --ion-color-primary: var(--ion-color-danger);
  /* text-transform: uppercase; */
  --color: rgba(255, 255, 255, .9);
  border: none;
  transition: all .5s;
}
.__hide-tab-bar ion-tab-bar {
  /* visibility: hidden; */
  /* opacity: 0; */
  /* transform: translateY(200%); */
  display: none;
  /* transform: translateY(200%);
  visibility: hidden; */
}
.tabs-inner {
  /* transition: min-height .5s; */
}
.__hide-tab-bar .tabs-inner {
  /* min-height: 100vh !important;
  flex: 1 !important;
  border: 3px solid rebeccapurple; */
}
.__no-ripple {
  --ripple-color: #badddb !important;
}

ion-tab-bar ion-icon {
  --ionicon-stroke-width: 1.5rem;
}

ion-tab-bar ion-tab-button {
  --padding-top: 4px;
  border-top: .55px solid white;
}

.__tab-bar-pb ion-tab-button {
  /* padding-bottom: 8px; */
}

ion-tab-bar .tab-selected {
  color: #fff;
  position: relative;
}

ion-tab-bar .tab-selected::after {
  content: '';
  display: block;
  position: absolute;
  width: 70%;
  height: 2px;
  background-color: #fff;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 0 0 4px 4px;
}

.__back-button {
  padding-right: 1rem;
  cursor: pointer;
}

.__text-center {
  text-align: center;
}

.__m-0 {
  margin: 0;
}

.__me-1 {
  margin-right: 1rem;
}

.__me-05 {
  margin-right: .5rem;
}

.__mt-0 {
  margin-top: 0;
}

.__mt-1 {
  margin-top: 1rem;
}
.__mb-1 {
  margin-bottom: 1rem;
}
.__pt-0 {
  padding-top: 0 !important;
}
.__pt-05 {
  padding-top: .5rem;
}

.__pt-1 {
  padding-top: 1rem;
}

.__pb-1 {
  padding-bottom: 1rem;
}

.__pb-05 {
  padding-bottom: .5rem;
}

.__ps-1 {
  padding-left: 1rem;
}

.__pe-1 {
  padding-right: 1rem;
}

.__px-1 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.__py-1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.__px-05 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.__bold {
  font-weight: bold;
}

.__avatar {
  border: .55px solid lightgray;
}

.__notifications-badge ion-badge {
  position: absolute;
  font-size: 8pt;
  right: -2px;
  top: 3px;
  padding-right: 6px;
  padding-left: 6px;
  --background: #eb445a;
}

.__notifications-badge ion-button {
  /* margin: 5px; */
  /* margin-right: 10px; */
  width: 40px;
  height: 40px;
  position: relative;
  overflow: visible !important;
}

/* Setting Overflow Visible */
.__notifications-badge ion-button.custom-class::part(native) {
  overflow: visible;
}

.__card {
  width: 100%;
  border-radius: 12px;
  /* box-shadow: 0 3px 16px rgba(0, 0, 0, 0.08); */
  background: var(--bg-color);
  position: relative;
  overflow: hidden;
}

.__card:not(:last-of-type) {
  margin-bottom: 1rem;
}

.__card.__post-social,
.__card.__single-product-offer-card,
.__card.__products-carousel-section,
.__card.__cart-card {
  width: calc(100% - 2rem);
  margin-left: 1rem;
  margin-right: 1rem;
}

.__carousel-container {
  overflow-x: scroll;
  display: flex;
  flex-wrap: nowrap;
}

.__carousel-container::-webkit-scrollbar {
  display: none;
}

.__post-container-header .__badge-text {
  color: grey;
  padding: .5rem 1rem 0;
  font-size: small;
}

.__post-container-header h3 {
  padding: 0 1rem 1rem;
  margin: 0;
  font-size: 1.2rem;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
}

.__cursive-title {
  /* font-family: 'Lobster', cursive; */
  /* letter-spacing: 1px; */
  font-weight: 600;
}

.__box-shadow-05 {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}
.__box-shadow-1 {
  box-shadow: 0 3px 16px rgba(0, 0, 0, 0.08);
}

.__box-shadow-2 {
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
}

.__divider {
  width: calc(100% - 2rem);
  height: .55px;
  margin: 0 auto;
  background: lightgray;
}

.__no-data {
  text-align: center;
  padding: 1rem;
}

.__no-data p {
  color: #777;
  margin: 0;
}

.__list-image {
  width: 60px;
  height: 60px;
  padding: .5rem;
}

.__list-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.__list-image.__round img {
  overflow: hidden;
  border-radius: 50% !important;
}

.__gradient {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 60px;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 1) 80%);
  border-radius: 0 16px 16px 0;
}

.__list-card {
  display: flex;
  align-items: center;
  padding: .5rem;
  margin-bottom: 1rem;
}
.__list-card-image {
  width: 70px;
  height: 70px;
  margin-right: 1rem;
  flex-shrink: 0;
}
.__list-card-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
.__list-card-shop-image {
  width: 100px;
  height: 70px;
}
.__list-card-shop-image img {
  border-radius: 8px;
  object-fit: cover;
}
.__list-card-shop .__list-card-text > * {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
.__list-card-shop .__list-card-text h3 {
  font-size: 1rem;
  margin: 0;
  margin-bottom: .3rem;
}
.__list-card-shop .__list-card-text p {
  margin: 0;
font-size: .9rem;
color: #555;
}
.__list-card-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 1rem;
}
.__list-card-price {
  flex-shrink: 0;
  margin-right: .5rem;
  margin-left: auto;
  font-weight: 600;
}