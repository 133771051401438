
.__chat-page {
  --width: 100vw;
}

.__chat-page .__chat {
  /* display: flex; */
  /* align-items: flex-end; */
  /* min-height: 100%; */
  /* transition: transform .3s; */
  /* position: absolute; */
  /* max-height: calc(100% - 200px); */
  /* overflow-y: scroll; */
}
/* .scroll-content {
  padding-bottom: 0 !important;
} */
.__chat-page ion-content {
  /* position: relative;
  overflow-y: hidden;
  touch-action: none; */
  /* --keyboard-offset: 344px; */
  /* padding-bottom: 0 !important; */
  /* max-height: 300px; */
}
.__chat-page .__chat .__day-chat {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.__chat-page .__chat .__message {
  width: 100%;
  margin-top: 1rem;
  display: flex;
}
.__chat-page .__chat .__message.self {
  justify-content: flex-end;
}
.__chat-page .__message .__avatar {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 1rem;
}
.__chat-page .__message .__bubble {
  padding: .5rem;
  background-color: lightgrey;
  max-width: 70%;
  min-width: 30%;
  border-radius: 8px;
  color: #444;
}
.__chat-page .__message.self .__bubble {
  background-color: var(--ion-color-primary-soft);
}
.__chat-page .__message.self .__bubble ion-label::after {
  display: table;
  content: "";
  clear: both;
}
.__chat-page .__message .__bubble span {
  /* float: left; */
}
.__chat-page .__message .__bubble p {
  float: right;
  padding-top: .5rem;
  padding-left: .5rem;
  /* text-align: right; */
}
.__chat-page .__message .__bubble p ion-icon {
  vertical-align: bottom;
  font-size: 20px;
  --ionicon-stroke-width: 40px;
}
ion-footer {
  /* position: absolute;
  bottom: 0; */
}
.__chat-page ion-content ion-toolbar {
  /* position: fixed; */
  /* bottom: 1.5rem; */
  transition: transform .3s;
}

.__chat-page ion-input {
  background-color: var(--ion-background-color);
  border-radius: 10px;
  margin-top: 4px;
  margin-bottom: 4px;
  --padding-start: 1rem !important;
  color: var(--ion-color-dark) !important;
}