.__product-card {
  height: auto;
  /* width: calc(50% - 1rem); */
  /* margin: 0.5rem; */
  width: 50%;
  text-decoration: none;
  border: .55px solid lightgray;
  background: var(--bg-color);
}
.__product-card-img {
  height: 170px;
  padding: 1rem;
}
.__product-card-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
.__product-card .__card-content {
  /* padding: 0 1rem 1rem; */
  /* display: flex;
  flex-direction: column;
  justify-content: space-between; */
  /* height: fit-content; */
}
.__product-card .__card-content .__card-title {
  margin-top: 0;
  padding: 0 1rem;
  color: var(--ion-text-color, #000);
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  font-size: 1rem;
  min-height: 2.4em;
  /* border: 0.55px solid var(--ion-border-color); */
  /* border: 2px solid black; */
}
.__product-card .__card-content .__price {
  padding: .5rem 1rem .5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: .8rem;
  background-color: var(--ion-color-light-tint);
}
.__product-card .__card-content .__price .__new-price {
  display: block;
  height: 24px;
  padding: 0 8px;
  border-radius: 6px;
  background-color: var(--ion-color-primary);
  color: #fff;
  font-weight: 700;
  line-height: 24px;
  /* font-size: .9rem; */
}
.__product-card .__card-content .__price .__discount {
  font-weight: 600;
  color: var(--ion-text-color, #000);
}
.__product-card .__card-content .__price .__old-price {
  text-decoration: line-through;
  display: block;
  font-weight: 400;
  color: grey;
}