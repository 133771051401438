:root {
  /* --ion-background-color: #dee0e6; */
  /* --ion-background-color: url(../../public/assets/bg1.png); */
  --ion-background-color: rgb(249, 249, 249);
  --bg-color: #fff;
  /* --ion-color-danger: #ff6150; */
  /* --ion-color-danger: #FF9F1C; */
  --ion-color-danger: #f08c16;
  --ion-color-danger-soft: rgb(241, 191, 134);
  /* --ion-color-primary: #2EC4B6; */
  --ion-color-primary: #18ab91;
  --ion-color-primary-soft: #badddb;
  --ion-color-blue: rgb(63, 145, 227);
  --ion-color-blue-soft: rgb(135,182,228);
  /* --blue-color: #134e6f; */
  --blue-color: var(--ion-color-primary);
  --light-blue-color: #66b7e5;
  --promo-color: #52489C;
  --promo-color: var(--ion-color-danger);
}