.__promo-carousel .__carousel-container {
  padding-bottom: 1rem;
}

.__promo-carousel .__promo-card {
  margin: 0;
  margin-right: .5rem;
  min-width: calc(100% / 1.5);
  background-color: var(--ion-color-light);
  transition: all .2s;
}
.__promo-carousel .__promo-card .__promo-card-img {
  width: 100%;
  height: 170px;
  padding: 1rem;
}
.__promo-carousel .__promo-card.__selected-promo {
  background-color: #fff;
}
.__promo-carousel .__promo-card.__selected-promo h5,
.__promo-carousel .__promo-card.__selected-promo p {
  /* color: #fff; */
}
.__promo-carousel .__promo-card .__promo-card-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.__promo-carousel .__promo-card h5 {
  margin: 0;
  padding: 0 1rem;
}
.__promo-carousel .__promo-card p {
  margin: 0;
  padding: .5rem 1rem 1rem;
  font-size: .9rem;
}