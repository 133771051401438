.__user-page .__user-heading {
  background-color: #fff;
  position: relative;
  z-index: 2;
}

.__user-page .__user-heading .__user {
  border-bottom: .55px solid lightgray
}

.__user-page .__user-heading .__user-points {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 1rem 1rem;
  width: calc(100% - 2rem);
}

.__user-page .__user-heading .__user-points-icon {
  padding: .5rem;
  margin-right: 1rem;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: linear-gradient(145deg, rgba(240, 140, 22, 1) 0%, rgba(241, 191, 134, 1) 100%);
}

.__user-page .__user-heading .__user-points-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.__user-page .__user-heading .__user-points p {
  margin: 0;
  text-align: center;
}
.__user-page .__user-heading .__user-points span {
  color: var(--ion-color-danger);
  font-weight: 500;
}

.__user-page ion-item-divider {
  --inner-padding-end: 0;
  --background: white;
  margin: 0;
  padding: 0;
  z-index: 1;
  border-bottom: .55px solid lightgray;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

.__user-page .__segments {
  width: 100%;
}

.__user .__user-info {
  display: flex;
  align-items: center;
}

.__user .__user-info .__avatar {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
}

.__user .__user-info .__info {
  margin-left: 1rem;
}

.__user .__user-info .__info h3 {
  margin: 0;
  border-bottom: 0.55px solid lightgrey;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
}

.__user .__user-info .__info p {
  margin: 0;
}

.__user-page .__transazione h4 {
  margin: 0;
}

.__user-page .__transazione ion-list {
  margin-top: .5rem;
}

.__user-page .__transazione ion-item {
  --padding-start: 0
}

.__user-page .__transazione ion-list,
.__user-page .__transazione ion-item {
  background: #fff;
  --background: #fff;
}

.__user-page .__user-messaggi {
  background-color: white;
}
.__user-page .__user-messaggi ion-item {
  --background: white;
}
.__user-messaggi .__chat-image {
  width: 60px;
  height: 60px;
  border: .55px solid grey;
  border-radius: 100%;
  overflow: hidden;
  margin: .5rem 1rem .5rem 0;
}
.__chat-image img {
  object-fit: cover;
  object-position: center;
}
.__user-messaggi .__last-message ion-icon {
  vertical-align: bottom;
  --ionicon-stroke-width: 40px;
}