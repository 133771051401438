.__shopping-card {
  width: 100%;
  margin: 0 auto;
  max-width: 400px;
  height: 200px;
  background-color: var(--ion-color-danger);
  border-radius: 16px;
  /* box-shadow: 0 3px 16px rgba(0, 0, 0, 0.3); */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 1rem;
}
.__shopping-card .__shopping-card-logo h2 {
  margin: 0;
  margin-bottom: 1rem;
  font-size: 2rem;
  text-align: center;
  color: #fff;
}
.__shopping-card .__shopping-card-barcode img {
  display: block;
  margin: 0;
  width: 250px;
  height: 50px;
  border-radius: 8px;
  padding: .3rem;
  background-color: white;
}
.__shopping-card .__user-info {
  margin-top: 1rem;
  font-size: 1.1rem;
  color: white;
  width: 100%;
  text-align: left;
  font-weight: 500;
}

.__wallet-button {
  max-width: 400px;
  margin-inline: 0;
  margin: 1rem auto 0;
}