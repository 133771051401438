.__video-player {
  position: relative;
  background-size: cover;
  background-position: center;
  background-color: black;
}
.__video-player .__volume-button {
  position: absolute;
  top: .5rem;
  right: .5rem;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.5);
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}
.__video-player video {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: relative;
}
.__video-player .__controls {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.3) 10%, transparent 90%);
  display: flex;
  align-items: center;
  opacity: 1;
  visibility: visible;
  transition: all .2s;
}
.__video-player .__controls.__hide {
  opacity: 0;
  visibility: hidden;
}

.__video-player .__controls .__play-button {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 .3rem 0 .5rem;
  cursor: pointer;
}
.__video-player .__controls .__duration {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 1rem;
}
.__video-player .__controls .__duration .__duration-label:first-of-type {
  margin-right: .7rem;
  margin-bottom: .2rem;
}
.__video-player .__controls .__duration .__duration-label:last-of-type {
  margin-left: .7rem;
  margin-bottom: .2rem;
}
.__video-player .__controls .__duration ion-range {
  padding: 0;
  --knob-size: 10px;
  --bar-background: rgba(255, 255, 255, 0.5);
  --bar-border-radius: 8px;
}
.__video-player .__ended-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.__video-player .__ended-video .__replay-button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.__video-player .__ended-video .__replay-button ion-icon {
  margin-bottom: .5rem;
}
.__video-player .__loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  /* background-color: black; */
  background-color: rgba(0, 0, 0, 0.5);

  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
}