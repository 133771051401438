.__home ion-header {
  position: relative;
}

.__home ion-toolbar {
  position: relative;
  z-index: 11;
}

.__home ion-refresher {
  margin-top: 2.7rem;
  /* background-color: #fff; */
}

.__header .__toolbar h1 {
  margin: 0;
  padding-left: 1rem;
  font-size: 1.4rem;
  letter-spacing: normal;
  /* color: var(--ion-color-primary); */
  /* color: var(--ion-color-danger); */
  max-width: max-content;
  cursor: pointer;
  color: #fff;
  font-family: 'Lobster', cursive;
  letter-spacing: 1px;
}

.__accordion-group {
  position: relative;
  z-index: 11;
}

.__card-accordion {
  /* padding: 1rem; */
  background: var(--blue-color);
  /* border-bottom: .55px solid lightgray; */
}

.__card-accordion h3 {
  margin: 0;
  padding: 1rem 1rem 0;
  font-size: 1.2rem;
  font-weight: 600;
}

.__card-accordion .__change-location {
  width: 100%;
  /* height: 100px; */
  background: #fff;
}

.__card-accordion .__change-location .__settings {
  padding-top: 1rem;
  font-weight: 500;
}

.__card-accordion .__change-location .__map {
  /* margin: 0 1rem 1rem; */
  width: 100%;
  height: 200px;
}

.__card-accordion .__change-location .__map img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.__home-heading {
  /* margin-top: 2.8rem; */
  /* padding: .5rem 1rem 0; */
  /* margin-top: .5rem; */
}

.__location {
  font-size: .9rem;
  display: flex;
  align-items: center;
  padding: .5rem 1rem;
  margin-bottom: .7rem;
  background: var(--bg-color);
  border-bottom: .55px solid lightgray;
  cursor: pointer;
  visibility: visible;
  opacity: 1;
  height: max-content;
  /* height: 30px; */
  transition: all .4s;
  position: absolute;
  width: 100%;
  /* position: fixed; */
  z-index: 10;
  /* top: auto;
  bottom:0;
  left: 0; */
}

.__location.show {
  visibility: visible;
}

.__location.hide {
  visibility: hidden;
  /* height: 0 !important; */
  /* opacity: 0; */
  transform: translateY(-150%);
  /* top: 0; */
}

.__location ion-text,
.__location ion-icon {
  margin: 0;
  margin-right: .3rem;
  font-weight: 300;
  /* color: var(--ion-color-danger); */
}

.__card-accordion .__search-accordion {
  position: relative;
  z-index: 1;
}

.__search-results {
  position: relative;
  /* height: 100vh; */
  /* height: 100%;
  min-height: max-content;
  overflow-y: scroll; */
}
.__search-results-bg {
  width: 100%;
  height: 100vh;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  z-index: 100;
}

.__search-results ion-list {
  position: absolute;
  z-index: 200;
  width: 100%;
  max-height: 60vh;
  /* min-height: min-content !important; */
  top: 0;
  overflow-y: scroll;
}
.__search-results ion-item {

  --background: #fff;
}

.__search-results .__search-image {
  width: 60px;
  height: 60px;
  padding: .5rem;
}
.__search-results .__search-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.__search-results .__search-image-shop img {
  overflow: hidden;
  border-radius: 50% !important;
}

.__home ion-refresher {
  margin-top: 70px;
}

.__home-heading h2 {
  margin: 0;
  margin-top: 2.7rem;
  padding: 0 1rem;
  /* font-size: 2rem; */
  font-weight: bold;
}

@media screen and (min-width: 400px) {
  .__home-heading h2 {
    font-size: 2rem;
  }
}

.__home-shops {
  background: #fff;
  margin: 0 1rem;
  width: calc(100% - 2rem);

}

.__home-shops h4 {
  margin: 0;
  padding: 1rem 1rem .5rem;
  font-weight: 600;
}

.__grid {
  padding: 0;
  --ion-grid-column-padding: 0;
}

.__products-card-swiper .swiper-wrapper {
  margin-top: 1rem;
}

.__products-card-swiper .swiper-pagination {
  top: 0 !important;
  height: max-content;
}

.__products-card-swiper .swiper-pagination-bullet-active {
  background-color: var(--ion-color-primary);
}