.__brand-page .__brand-page-content {
  /* position: relative; */
}
.__brand-page .__brand-heading {
  background-color: #fff;
  border-bottom: .55px solid lightgray
}
.__brand-page .__brand-heading-top {
  display: flex;
  align-items: center;
  padding: 1rem;
  border-bottom: .55px solid lightgray
}
.__brand-page .__avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  padding: 3px;
  border-width: 2px;
  border-color: var(--ion-color-primary);
  margin-right: 1rem;
}
.__brand-page .__avatar img {
  border-radius: 50%;
}
.__brand-page h1 {
  font-size: 1.2rem;
  font-weight: 500;
}
.__brand-page .__follow-btn {
  margin-left: auto;
  margin-right: 0;
}
.__brand-page .__follow-btn.__follow {
  --background: var(--ion-color-danger);
}
.__brand-page .__follow-btn.__unfollow {
  --background: var(--ion-color-danger-soft);
  /* --color: var(--ion-color-danger); */
}
.__brand-page .__brand-heading .__segments {
  /* position: sticky !important; */
  /* position: -webkit-sticky; */
  position: sticky;
  top: 200px !important;
  left: 0px;
}
.__brand-info {
  padding: 0 1rem;
}
.__brand-page h4 {
  margin: 0;
}
.__brand-page .__brand-shop-card {
  display: flex;
  margin-top: 1rem;
  flex-direction: row;
}
.__brand-page .__brand-shop-image {
  width: 150px;
  height: 120px;
  padding: .5rem;
  margin-right: .5rem;
}
.__brand-page .__brand-shop-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 6px;
}
.__brand-page .__brand-shop-card p {
  margin: 0;
  color: #555;
}
.__brand-info-element {
  border-bottom: .55px solid lightgray;
  padding: .5rem;
  display: flex;
  align-items: center;
}
.__brand-info-element ion-router-link:not(.__maps-link),
.__brand-info-element ion-icon:not(.__maps-link) {
  color: #555;
}
.__brand-info-element.__hours {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.__brand-info-element span {
  color: #555;
  display: inline-block;
}
.__brand-info-element ion-icon {
  margin-right: .5rem;
}
.__brand-info-element:last-of-type {
  margin-bottom: 1rem;
}
.__brand-info-map {
  border-radius: 8px;
  overflow: hidden;
  height: 200px;
  margin-bottom: 1rem;
}
.__brand-info-map img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.__brand-info-header {
  /* display: flex; */
  /* align-items: center; */
  font-weight: 600;
  /* font-size: 1.3rem; */
}