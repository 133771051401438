.__products-carousel-section {
  position: relative;
}

.__products-carousel-section .__carousel-container {
  padding: .5rem 1rem 2rem;
}


.__products-carousel-section .__post-container-header h3 {
  padding: 0rem 1rem;
  margin-bottom: .5rem;
}