.__products-title {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.__products .__contents {
  display: flex;
  flex-wrap: wrap;
  /* padding: 0 0.5rem 0.5rem; */
}

.__products h2 {
  font-size: 1.3rem;
  font-weight: 600;
  margin: 0;
}