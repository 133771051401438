.__promozione .__hero img  {
  display: block;
}
.__promozione .__logo-negozio {
  height: 50px;
}

.__promozione .__info-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0;
}