@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');

@font-face {
  font-family: airBnbCereal;
  src: url(./font/AirbnbCereal_W_Lt.otf);
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: airBnbCereal;
  src: url(./font/AirbnbCereal_W_Bk.otf);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: airBnbCereal;
  src: url(./font/AirbnbCereal_W_Md.otf);
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: airBnbCereal;
  src: url(./font/AirbnbCereal_W_Bd.otf);
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: airBnbCereal;
  src: url(./font/AirbnbCereal_W_XBd.otf);
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: airBnbCereal;
  src: url(./font/AirbnbCereal_W_Blk.otf);
  font-weight: 900;
  font-style: normal;
}

* {
  font-family: airBnbCereal;
  /* font-family: 'Lato', sans-serif; */
  font-family: 'Outfit', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
div {
  color: #192c2a;
}

