.__header .toolbar-background,
.__header .toolbar-container,
.__header .__toolbar {
  /* --border-width: 0 !important; */
}

.__segments::-webkit-scrollbar {
  display: none;
}

.__segments-wrapper {
  /* position: sticky;
  top: 0;
  z-index: 999;
  margin-top: 0; */
  /* background: #fff; */
  /* padding-bottom: 1rem; */
  /* border-width: 0; */
  /* border-style: solid; */
  /* border-bottom-width: 0.55px; */
  /* border-color: var(--ion-toolbar-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.2)))); */
  /* border-style: var(--border-style); */
}

.__segments.ios {
  margin: 0 1rem;
}

.__segment-button {
  min-width: auto;
  /* --background: */
}

.__segment-button.segment-button-checked {
  --indicator-color: var(--ion-color-primary);
}

.__segment-button.segment-button-checked.ios {
  color: white
}
ion-toolbar.toolbar-searchbar {
  transition: transform .4s, visibility .4s;
  z-index: -1;
  position: absolute;
}
ion-toolbar.toolbar-searchbar.hide {

  visibility: hidden;
  /* height: 0 !important; */
  /* opacity: 0; */
  transform: translateY(-150%);
  /* top: 0; */

}

.__offerte .__search-results {
  position: relative;
  /* height: 100vh; */
  /* height: 100%;
  min-height: max-content; */
  /* overflow-y: scroll; */
  /* background: transparent; */
}

.__offerte .__search-results-bg {
  width: 100%;
  /* height: 100%; */
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  z-index: -2;
}

.__offerte .__search-results ion-list {
  position: absolute;
  /* z-index: -1; */
  /* width: 100%; */
  /* max-height: 100%; */
  /* min-height: min-content !important; */
  /* top: 0; */
  overflow-y: scroll;
}

.__offerte .__search-results ion-item {

  --background: #fff;
}

.__search-results .__search-image {
  width: 60px;
  height: 60px;
  padding: .5rem;
}

.__search-results .__search-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.__search-results .__search-image-shop img {
  overflow: hidden;
  border-radius: 50% !important;
}

.__offerte .__filters {
  padding-top: 4rem;
  /* padding-left: 1rem; */
}

.__offerte .__filters h3 {
  margin: 0;
  margin-left: 1rem;
  flex-shrink: 0;
}

.__offerte .__filters .__categories {
  /* display: flex;
  align-items: center;
  width: 100%; */
}
.__offerte .__filters .__categories .__chips {
  flex-shrink: 0;
  flex: 1;
  /* min-width: max-content; */
  overflow-x: scroll;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  padding: .5rem 1rem 0;
}
.__offerte ion-chip:not(.chip-outline) {
  --background: var(--ion-color-danger);
  --color: white;
}
.__offerte ion-chip.chip-outline {
  color: var(--ion-color-danger);
  border-color: var(--ion-color-danger);
  border-width: .55px;
}
.__offerte .__filters .__categories .__cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-right: 1rem;
  margin-left: 1rem;
  margin-top: 1rem;
}

.__category-card {
  width: calc(50% - .5rem);
  height: 100%;
  flex-shrink: 0;
}
.__category-card:nth-last-of-type(2) {
  margin-bottom: 0;
}
.__category-card .__category-img {
  width: 100%;
  height: 150px;
}
.__category-card .__category-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.__category-card .__category-name {
  padding: 1rem;
}
.__category-card .__category-name p {
  font-size: 1.1rem;
  margin: 0;
}


.__offerte .__filters .__categories .__chips ion-chip {
  flex-shrink: 0;
}

.__offerte .__offerte-feed {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
}

ion-modal#filter-modal {
  --height: 50vh;
  --width: 70vw;
  --border-radius: 16px;
}

.__grid {
  padding: 0;
  --ion-grid-column-padding: 0;
}