.__notification-wrapper {
  width: 100%;
  /* height: 90px; */
  border-bottom: .55px solid lightgray;
  display: flex;
  align-items: center;
  padding: 1rem;
  background: #eee;
}
.__notification-wrapper.__to-read {
  background: #fff;
}

.__notification-avatar {
  border-radius: 50%;
  overflow: hidden;
  width: 50px;
  height: 50px;
  margin-right: 1rem;
  flex-shrink: 0;
  border: 1px solid var(--ion-color-primary-soft);
}
@media screen and (min-width: 400px) {
  .__notification-avatar {
    width: 60px;
    height: 60px;

  }
}
.__notification-avatar img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
.__notification-text {
  /* font-size: .9rem; */
}
.__notification-text p {
  /* color: grey; */
  margin: 0;

}
.__notification-text .__notification-date {
  font-size: .8rem;
  color: gray;
  margin-top: .5rem;
}