.__shops-carousel {
  display: flex;
  overflow-x: scroll;
  padding: .5rem 60px 1rem 1rem;
}
.__shop-carousel-link {
  display: block;
  text-align: center;
  color: black;
  text-decoration: none;
}
.__shop-carousel-link:not(:last-of-type) {
  margin-right: 1rem;
}
.__shop-carousel-avatar {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
  border: .55px solid lightgray;
}
.__shop-carousel-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.__shop-carousel-link p {
  margin: 0;
  margin-top: .5rem;
  font-size: .9rem;
  /* font-weight: 400; */
}