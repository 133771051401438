.__card-carousel.__card {
  width: calc(100% / 2.2);
  min-width: calc(100% / 2.2);
  flex-shrink: 0;
  overflow: hidden;
  height: auto;
  border-radius: 10px;
  margin-right: .5rem;
  margin-bottom: 0 !important;
  text-decoration: none;
  color: unset;
  position: relative;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  /* justify-content: stretch; */
}

@media screen and (max-width: 400px) {
  .__card-carousel.__card {
    width: calc(100% / 1.5);
    min-width: calc(100% / 1.5);
  }
}

.__card-carousel.__card-plus {
  justify-content: center;
}
.__card-carousel.__card .__plus {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: .8rem;
  color: var(--ion-color-primary);
}

.__card-carousel.__card .__plus ion-icon {
  font-size: 3rem;
  color: var(--ion-color-primary);
}

.__card-carousel.__offset-right {
  padding-right: 4rem;
}

.__card-carousel.__card .__img {
  width: 100%;
  height: 150px;
}

.__card-carousel.__card .__img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.__card-carousel.__card .__card-info {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  /* height: fit-content; */
}

.__card-carousel.__card .__card-info h4 {
  margin: 0;
  font-size: .9rem;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  font-weight: 300;
}

.__card-carousel.__card .__card-info-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: .5rem;
}
.__card-carousel.__card .__card-info-bottom .__right {
  text-align: right;
}

.__card-carousel.__card .__card-info .__price {
  display: block;
  height: 24px;
  padding: 0 8px;
  border-radius: 12px;
  background-color: var(--ion-color-primary);
  color: #fff;
  font-weight: 600;
  line-height: 24px;
  font-size: .8rem;
}
.__card-carousel.__card .__card-info .__discount {
  font-size: .8rem;
  font-weight: 600;
  color: var(--ion-color-danger);
}
.__card-carousel.__card .__card-info .__old-price {
  font-size: .8rem;
  text-decoration: line-through;
  color: grey;
}

.__card-carousel.__card .__shop-avatar {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  border: 2px solid var(--ion-color-primary);
  overflow: hidden;
  position: absolute;
  top: 12px;
  right: 12px;
}

.__card-carousel.__card .__shop-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  display: block;
}