.__prodotto .__gallery {
  height: 250px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.__prodotto .__gallery img {
  margin: 0 auto;
}
.__prodotto .__categories ion-chip {
  text-transform: capitalize;
}
.__prodotto .__info-card {
  /* overflow: hidden; */
  margin: 1rem;
  width: calc(100% - 2rem);
  /* border-radius: 4px; */
  /* box-shadow: 0 3px 16px rgba(0, 0, 0, 0.08); */
  background-color: var(--bg-color);
}
.__prodotto .__info-card .__title {
  border-bottom: .55px solid lightgray;
  padding: 1rem;
}
.__prodotto .__info-card .__title h5 {
  margin: 0;
}
.__prodotto .__info-card ion-list,
  .__prodotto .__info-card ion-item {
  background: transparent;
  --background: white
}
.__prodotto .__product-caratt.__info-card ion-list ion-item ion-label:nth-child(2) {
  text-align: right;
}
.__prodotto .__price-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}
.__prodotto .__price-button ion-label {
  color: grey;
  font-size: .9rem;
}

.__prodotto .__price-side {
  display: block;
  /* width: 0%; */
}

.__prodotto .__price {
  /* width: 50%; */
  /* margin-left: auto;
  margin-right: 0; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.__prodotto .__price .__new-price {
  display: block;
  height: 24px;
  padding: 0 8px;
  border-radius: 12px;
  background-color: var(--ion-color-primary);
  color: #fff;
  font-weight: 600;
  line-height: 24px;
}

.__prodotto .__price .__discount {
  font-weight: 600;
  color: var(--ion-text-color, #000);
}

.__prodotto .__price .__old-price {
  text-decoration: line-through;
  display: block;
  font-weight: 400;
  color: #666;
}

.__prodotto .__button {
  margin-left: auto;
  margin-right: 0;
}

.__prodotto .__action-buttons .__buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 1rem 0;
}
.__prodotto .__action-buttons .__card {
  /* width: calc(50% - 1.5rem);
  margin-bottom: 0;
  margin-left: 1rem;
  padding: .5rem; */
  width: 100%;
}
.__prodotto .__action-buttons .__card:nth-child(2) {
  /* margin-right: 1rem; */
}
.__prodotto .__action-buttons .__card {
  /* display: flex;
  justify-content: center; */
  /* width: 100%; */
}
.__prodotto .__action-buttons  ion-button {
  /* width: 100%; */
  --padding-top: .5rem;
  --padding-bottom: .5rem;
  --padding-start: .5rem;
  --padding-end: .5rem;
  height: 2.5rem;
  width: calc(50% - .5rem);
  /* background-color: white; */
}
.__prodotto .__action-buttons ion-icon {
  /* flex-shrink: 0; */
  margin-left: .3rem;
}
.__prodotto .__action-button {
}

.__prodotto .__best-offer {
  border-radius: unset;
}

.__prodotto .__shops-list-title h3 {
  padding-left: 1rem;
}
.__prodotto .__best-price-location {
  /* align-items: center;
  display: flex; */
  padding: 0.5rem 1rem !important;
  margin: 0 !important;
  --padding-start: 0;
  border-top: 0.55px solid lightgray;
  --background: var(--bg-color);
  /* padding: 1rem; */
}
/* .__prodotto .__best-price-location .__best-price-icon {
  flex: 1;
} */

.__prodotto .__product-description {
  font-size: .9rem;
}
.__prodotto .__product-description p {
  margin: 0;
}
.__prodotto .__nutriscore {
  display: flex;
  align-items: center;
  padding: 1rem;
}
.__prodotto .__nutriscore img {
  height: 70px;
  margin-right: 1rem;
}
.__prodotto .__nutriscore p,
.__prodotto .__nutriscore h5 {
  margin: 0;
}
.__prodotto .__nutriscore h5 {
  font-size: 1.3rem;
  font-weight: 400;
  margin-bottom: .5rem;
}
.__prodotto .__nutriscore h5 span {
  text-transform: capitalize;
}
.__prodotto .__nutriscore.__score-e {
  background-color: rgb(250, 203, 201);
}
.__prodotto .__nutriscore.__score-e h5,
.__prodotto .__nutriscore.__score-e h5 span {
  color: rgb(236, 80, 80);
}
.__prodotto .__nutriscore.__score-d {
  background-color: rgb(251, 219, 193);
}
.__prodotto .__nutriscore.__score-d h5,
.__prodotto .__nutriscore.__score-d h5 span {
  color: rgb(224, 115, 18);
}
.__prodotto .__nutriscore.__score-a {
  background-color: rgb(190, 227, 198);
}
.__prodotto .__nutriscore.__score-a h5,
.__prodotto .__nutriscore.__score-a h5 span {
  color: rgb(34, 150, 83);
}
.__prodotto .__nutriscore.__score-c {
  background-color: rgb(244, 233, 210);
}
.__prodotto .__nutriscore.__score-c h5,
.__prodotto .__nutriscore.__score-c h5 span {
  color: rgb(201, 143, 2);
}
.__prodotto .__nutriscore.__score-b {
  background-color: rgb(224, 239, 211);
}
.__prodotto .__nutriscore.__score-b h5,
.__prodotto .__nutriscore.__score-b h5 span {
  color: rgb(95, 173, 14);
}
.__prodotto .__product-promo .__promo-item:not(:last-of-type) {
  border-bottom: .55px solid lightgrey;
}
.__prodotto .__product-promo ion-router-link {
  color: grey;
  padding: 1rem 0;
  font-size: .9rem;
  display: block;
}
.__prodotto .__product-promo ion-router-link > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.__prodotto .__product-promo ion-router-link .__promo-title {
  color: var(--ion-color-danger);
  font-size: 1rem;
  font-weight: 600;
}
.__prodotto .__product-promo ion-router-link ion-icon {
  font-size: 24px;
  color: var(--ion-color-danger);
}

.__prodotto .__single-product-shops {
  border-radius: inherit;
}
.__prodotto .__single-product-shops .__shops-list-title {
  border-bottom: .55px solid lightgray;
}
.__prodotto .__single-product-shop {
  padding-right: 1rem;
}