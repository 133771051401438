.__premi .__banner-premi {
  width: 100%;
  background: linear-gradient(145deg, rgba(240, 140, 22, 1) 0%, rgba(241, 191, 134, 1) 100%);
  display: flex;
  align-items: center;
  padding: 1rem;
  /* margin-bottom: 1rem; */
}

.__premi .__banner-premi img {
  margin-right: 1rem;
}

.__premi .__banner-premi h2 {
  color: white;
}
.__premi ion-item-divider {
  /* width: 100%; */
  padding: 0;
  margin: 0;
  --inner-padding-end: 0;
}
.__premi .__segments {
  background: white;
  width: 100%;
}

.__premi h4 {
  margin: 0;
  margin-bottom: .5rem;
}
.__points-number {
  display: flex;
  align-items: center;
}
.__points-number img {
  display: inline-block;
  margin-right: .5rem;
}
.__premi h5 {
  margin: 0;
}

.__premi .__lista-premi {
  display: flex;
  flex-wrap: wrap;
  padding-top: 1rem;
}

.__premi .__premio-card {
  width: calc(50% - .5rem);
  height: 100%;
  padding: 1rem;
  margin-bottom: 1rem;
}

.__premi .__premio-card .__img {
  width: 100%;
  height: 150px;
}

.__premi .__premio-card .__img img {
  object-fit: contain;
  object-position: center;
  width: 100%;
  height: 100%;
}

.__premi .__premio-card:nth-child(odd) {
  margin-right: 1rem;
}

.__premi .__premio-nome p {
  margin: 0;
  margin-top: 1rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
}
.__premi .__premio-punti {
  text-align: right;
}
.__premi .__premio-punti ion-chip {
  margin: 0;
  margin-top: 1rem;
}